// src/components/AboutMe.js

import React from 'react';
import './AboutMe.css'; // Import the CSS file for styling
import profilePhoto from './pfp.jpeg'
const AboutMe = () => {
  return (
    <div className="about-me">
      <div className="container">
        <div className="header">
          <img src={profilePhoto} alt="Krish Shah" className="profile-photo" />
          <h1>Krish Shah</h1>
          <p className="subtitle">Aspiring Software Engineer and Quant</p>
        </div>
        <div className="content">
          <section className="about-section">
            <h2>About Me</h2>
            <p>Hello! I'm Krish Shah, and I'm currently pursuing a B.S.E. in Computer Science and Engineering at the University of Connecticut with a concentration in Computational Data Analytics and a minor in Mathematics. I'm set to graduate in May 2026.</p>
            <p>I have a passion for machine learning, finance, and software development. I'm constantly seeking to learn new technologies and improve my skills. I enjoy solving complex problems and am enthusiastic about the opportunities that technology brings to make the world a better place.</p>
            <p>When I'm not coding, you can find me lifting weights, training Muay Thai, or reading about the latest tech. Feel free to connect with me!</p>
          </section>
          <section className="experience-section">
            <h2>Experiences</h2>
            <div className="experience">
              <h3>Remote Software Engineer Intern</h3>
              <p class="italic">Berkley Small Business Solutions - September 2024 - Present</p>
              <ul>
                <li>Leading the development of a web-based application using Angular and TypeScript, enabling insurance underwriters to edit and manage extremely nested JSON policy administration configuration files</li>
                <li>Building a REST API with C# to securely and efficiently manage configuration files stored in a MS SQL Server</li>
                <li>Leveraging Git, Bitbucket, and Jira for version control, code reviews, and Agile task tracking </li>
              </ul>
            </div>
            <div className="experience">
              <h3>Software Engineer Intern</h3>
              <p class="italic">Berkley Small Business Solutions - June 2024 to August 2024</p>
              <ul>
                <li>Developed a Python application with object-oriented programming principles to automate state-level customization of Berkley’s policy admin system, reducing manual state-level configuration time by 80%</li>
              </ul>
            </div>
            <div className="experience">
              <h3>Analyst</h3>
              <p class="italic">Hillside Ventures - March 2024 to Present</p>
              <ul>
                <li>Sourcing SaaS (cloud computing and AI) Pre-Seed to Series A startups for UConn’s student-run $1mm VC fund</li>
                <li>Conducting technical and financial due diligence to assess product design and market potential, mitigating risk</li>
              </ul>
            </div>
            <div className="experience">
              <h3>Artificial Intelligence Research Assistant</h3>
              <p class="italic">El Gato Laboratory - April 2023 to June 2024</p>
              <ul>
                <li>Developed and tested machine learning models to predict motion-to-strike outcomes in tort and vehicular cases, utilizing algorithms such as XGBoost, AdaBoost, and Sparse Gaussian Process Classifiers</li>
                <li>Increased prediction accuracy by 10% through data preprocessing, feature engineering, and model validation</li>
              </ul>
            </div>
            <div className="experience">
              <h3>Technical Support Specialist</h3>
              <p class="italic">UConn Information Technology Services - January 2024 to May 2024</p>
              <ul>
                <li>Developed and communicated technical solutions to non-technical users, improving customer satisfaction</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
